import { yup } from '@swe/shared/ui-kit/components/form';

type FormFields = {
  isResident: true | null;
  email: string;
  actionType: string;
};

const VALIDATION_SCHEMA = yup.object().shape({
  isResident: yup.boolean().nullable(),
  email: yup.string().email('Email is incorrect').required('Email is required'),
  actionType: yup.string().required('Select request type'),
});

const INITIAL_VALUES: FormFields = {
  isResident: null,
  email: '',
  actionType: '',
};

const PERSONAL_INFORMATION_FORM = {
  validationSchema: VALIDATION_SCHEMA,
  initialValues: INITIAL_VALUES,
};

export type { FormFields };
export { PERSONAL_INFORMATION_FORM, VALIDATION_SCHEMA, INITIAL_VALUES };
