import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import PageHeader from 'common/components/page-header';
import { DEFAULT_AUTHORIZED_PAGE_PROPS } from 'common/components/page/config';
import { Routes } from 'common/router/constants';
import { PersonalInformationForm } from 'domains/profile/containers/privacy-and-security/containers/personal-information-form';

const TITLE = 'Personal Information';

const ProfilePersonalInformationPage: ApplicationPage = () => {
  return (
    <Page {...DEFAULT_AUTHORIZED_PAGE_PROPS}>
      <PageHeader
        title={TITLE}
        defaultBackRoute={Routes.Profile}
        enableMarginBottom
      />
      <PersonalInformationForm />
    </Page>
  );
};

ProfilePersonalInformationPage.getMeta = () => ({ title: TITLE });

ProfilePersonalInformationPage.getMainLayoutProps = () => ({
  size: 'md',
  centered: 'x',
});

export default ProfilePersonalInformationPage;
